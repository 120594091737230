// import React from 'react'
import React from 'react'
import './App.css'
import Layout from './Components/Layouts/Layout'

function App() {
  return (
    <Layout/>
  )
}

export default App