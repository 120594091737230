import React from 'react'
import galleryImg01 from '../../Assets/Images/gallery-01.jpg';
import galleryImg02 from '../../Assets/Images/gallery-02.jpg';
import galleryImg03 from '../../Assets/Images/gallery-03.jpg';
import galleryImg04 from '../../Assets/Images/gallery-04.jpg';
import galleryImg05 from '../../Assets/Images/gallery-05.jpg';
import galleryImg06 from '../../Assets/Images/gallery-06.jpg';
import galleryImg07 from '../../Assets/Images/gallery-07.jpg';






const galleryImages = [
    galleryImg01,
    galleryImg07, 
    galleryImg02,
    galleryImg06,
    galleryImg03,
    galleryImg04,
    galleryImg05,
    galleryImg07, 
]

export default galleryImages