

import React from 'react'
import { Container, Row, Col, ListGroup, ListGroupItem } from 'reactstrap';

import './commonsection.css'

const CommonSection = ({title}) => {
  return (
    <section className='common__section'>
        <Container>
            <Row>
                <Col lg='12'>
                    <h1>{title}</h1>
                </Col>
            </Row>
        </Container>

    </section>

  )
}

export default CommonSection